<script>
    import Layout from "../../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Monitor from "@/services/Monitor";
    import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
    import _ from 'underscore';

    export default {
        components: { Layout, PageHeader,VueBootstrapTypeahead},
        page: {
            title: "API Errors",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                title: "CRON Log",
                items: [
                    {
                        text: "Logs",
                    },
                    {
                        text: "CRON Log",
                        active: true,
                    },
                ],
                isIntervalOn: true,
                selectedTenant: '',
                tenantList: [], 
                originalCronLogs: [], 
                isBusy: false,
                queuesData: {},
                showLoader:false,
                error: '',
                tableData: [],
                totalRows: 0,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "timestamp",
                sortDesc: true,
                fields: [
                    {
                        key: "id",
                        label: "ID",
                        sortable: true
                    },
                    {
                        key: "cron",
                        label: "Cron",
                        sortable: false
                    },
                    {
                        key: "tenant",
                        label: "Tenant",
                        sortable: true
                    },
                    {
                        key: "status",
                        label: "Status",
                        sortable: true
                    },
                    {
                        key: "arguments",
                        label: "Arguments",
                        sortable: false
                    },
                    {
                        key: "response",
                        label: "Response",
                        sortable: false
                    },
                    {
                        key: "start_at",
                        label: "Start At",
                        sortable: true
                    },
                    {
                        key: "end_at",
                        label: "End At",
                        sortable: true
                    },
                    {
        key: "time_difference",
        label: "Time",
        sortable: false
    },
                    "action",
                ]
            };
        },
        destroyed() {
            clearInterval(this.timer);
        },
        middleware: "authentication",
        async mounted() {
            try {
                await this.getCronLogs();
                this.setupInterval(); 
            } catch (error) {
                console.error('Error during component initialization:', error);
            }
        },
        methods: {
            getTimeDifference(startAt, endAt) {
        if (!startAt || !endAt) return '';
        
        const startTime = new Date(startAt);
        const endTime = new Date(endAt);
        const timeDiff = (endTime - startTime) / 1000; 

        const hours = Math.floor(timeDiff / 3600);
        const minutes = Math.floor((timeDiff % 3600) / 60);
        const seconds = Math.floor(timeDiff % 60);

        return `${hours}h ${minutes}m ${seconds}s`;
    },

async getCronLogs(tenant) {
            this.toggleBusy(); 
            try {
                const response = await Monitor.getCronLogs(); 
                this.originalCronLogs = response.data.data; 

                const tenants = [...new Set(this.originalCronLogs.map(item => item.tenant))];
                this.tenantList = tenants.map(tenant => ({ text: tenant }));

                if (tenant) {
                    this.filterTableData(tenant);
                } else {
                    this.tableData = this.originalCronLogs; 
                }
                this.totalRows = this.tableData.length;
            } catch (error) {
                this.error = error.response?.data?.error || "Error fetching data"; 
                this.tableData = [];
                this.totalRows = 0;
            } finally {
                this.toggleBusy(); 
            }
        },
          
            setupInterval() {
        if (this.isIntervalOn) {
            this.timer = setInterval(async () => {
                try {
                    await this.getCronLogs(this.selectedTenant);
                } catch (error) {
                    console.error('Error in interval:', error);
                }
            }, 120000); 
        } else {
            clearInterval(this.timer);
        }
    },
            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
          
   
            onTenantSelect(selectedItem) {
    this.selectedTenant = selectedItem.text; 
    this.filterTableData(this.selectedTenant); 
},

filterTableData(tenant) {
        if (tenant) {
            this.tableData = this.originalCronLogs.filter(item => item.tenant === tenant);
        } else {
            this.tableData = [];  
        }
        this.totalRows = this.tableData.length;
    },

toggleBusy() {
            this.isBusy = !this.isBusy; 
        },

        clearTableData() {
        this.selectedTenant = '';  
        this.tableData = []; 
        this.totalRows = 0;   
    },

        }
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

    <div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="custom-accordion">
                    <a class="text-body fw-semibold pb-2 d-block" data-toggle="collapse"
                        href="javascript: void(0);" role="button" aria-expanded="false"
                        v-b-toggle.filters-collapse>
                        <i class="mdi mdi-chevron-up accor-down-icon text-primary me-1"></i>Filters
                    </a>
                    <b-collapse visible id="filters-collapse">
                        <div class="card p-2 border shadow-none">
                            <div class="row align-items-center">
                                <div class="col-sm-12 col-md-6 d-flex align-items-center">
                                    <b-form-group label="Tenant" label-for="formrow-tenant-input" class="mb-0 me-3">
                                        <vue-bootstrap-typeahead 
                                            id="formrow-tenant-input" 
                                            ref="tenantAutocomplete"
                                            v-model="selectedTenant"
                                            :data="tenantList"
                                            :serializer="s => s.text"
                                            placeholder="Search tenant..."
                                            @hit="onTenantSelect"
                                            :minMatchingChars="1"
                                            @input="clearTableData"
                                        />
                                    </b-form-group>
                                </div>

                                <div class="col-sm-12 col-md-6 d-flex mt-auto align-items-center justify-content-end">
                                    <label class="form-check-label me-3 mb-0" for="intervalToggleSwitch">Page-refresh</label>
                                    <div class="form-check form-switch form-switch-lg">
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            id="intervalToggleSwitch"
                                            v-model="isIntervalOn"
                                            @change="setupInterval"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-collapse>
                </div>
            </div>
        </div>
             <div class="card">
                    <div class="card-body" v-if="selectedTenant">
                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select v-model="perPage" size="sm"
                                            :options="pageOptions"></b-form-select>&nbsp;entries
                                    </label>
                                </div>
                            </div>
                           
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input v-model="filter" type="search" placeholder="Search..."
                                            class="form-control form-control-sm ms-2"></b-form-input>
                                    </label>
                                </div>
                            </div>
                        
                        </div>

                        <div class="table-responsive mb-0">
                            <b-table
                                :busy="isBusy"
                                :items="tableData"
                                :fields="fields"
                                responsive="sm"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                @filtered="onFiltered"
                                show-empty
                                empty-text="No Data Found">

                                <template #table-busy>
                                    <div class="text-center text-black my-2">
                                        <b-spinner class="align-middle text-primary"></b-spinner>
                                        <strong class="text-primary">Loading...</strong>
                                    </div>
                                </template>

                                <template v-slot:cell(arguments)="tableData">
                                    <div class="d-flex align-items-center">
                                        <span v-if="tableData.item.arguments && tableData.item.arguments.length > 30" class="text-nowrap">
                                            {{ tableData.item.arguments.slice(0, 30) + '...' }}
                                        </span>
                                        <a 
                                            v-if="tableData.item.arguments && tableData.item.arguments.length > 30"
                                            v-b-popover.hover.html.bottom="tableData.item.arguments"
                                            class="elementPointed ms-2"
                                            title="Arguments"
                                            size="sm"
                                        >
                                            <i class="uil uil-eye font-size-18"></i>
                                        </a>
                                        <span v-else>{{ tableData.item.arguments }}</span>
                                    </div>
                                </template>

                                <template v-slot:cell(response)="tableData">
                                    <div class="d-flex align-items-center">
                                        <span v-if="tableData.item.response && tableData.item.response.length > 30" class="text-nowrap">
                                            {{ tableData.item.response.slice(0, 30) + '...' }}
        </span>
        <a 
                                            v-if="tableData.item.response && tableData.item.response.length > 30"
                                            v-b-popover.hover.html.bottom="tableData.item.response"
                                            class="elementPointed ms-2"
                                            title="Response"
                                            size="sm"
                                        >
                                            <i class="uil uil-eye font-size-18"></i>
                                        </a>
                                        <span v-else>{{ tableData.item.response }}</span>
                                    </div>
                                </template>
                                <template v-slot:cell(time_difference)="data">
        <span>{{ getTimeDifference(data.item.start_at, data.item.end_at) }}</span>
    </template>

                            </b-table>
                        </div>
                        <div class="row mt-3">
                            <div class="col-sm-12 col-md-5">
                                <div class="dataTables_info">
                                    Showing {{ (currentPage - 1) * perPage + 1 }} to
                                    {{ (currentPage - 1) * perPage + totalRows  }} of {{ totalRows }} entries
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-7">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                                    align="right" size="sm"></b-pagination>
                            </div>
                        </div>
                    </div>
                    <div v-else class="text-center mt-4 p-4">
                        <strong>Use the filter to display the data</strong>
                    </div>
                </div>
            </div>
</div>
        </Layout>
</template>